import { useTranslation } from '@hopin-team/provider-translation';
import React, { lazy, Suspense } from 'react';

import { useIsFreePlan } from '@/hooks/useIsFreePlan';
import { useRootDispatch, useRootSelector } from '@/store';
import { getEvent, getUpgradeDialogOpen, getUser } from '@/store/selectors';

import { Spinner } from './icons';

const UpgradeDialog = lazy(() =>
  import('@rce-web/ui-upgrade-dialog').then(({ UpgradeDialog }) => ({
    default: UpgradeDialog,
  })),
);

const UpgradeDialogLoader = () => {
  const { t } = useTranslation();
  const event = useRootSelector(getEvent);
  const user = useRootSelector(getUser);
  const upgradeDialogOpen = useRootSelector(getUpgradeDialogOpen);
  const dispatch = useRootDispatch();
  const { isFreePlan, isFreeTrialPlan, isDraft } = useIsFreePlan();

  if (!isFreeTrialPlan && !isFreePlan && !isDraft) return null;

  const onClose = () =>
    void dispatch({
      type: 'upgrade dialog closed',
    });

  if (!event) {
    return null;
  }
  return (
    <Suspense fallback={<Spinner />}>
      <UpgradeDialog
        isOpen={upgradeDialogOpen}
        onClose={onClose}
        options={{
          host: import.meta.env.VITE_HOPIN_DASHBOARD_APP_HOST,
          organisationId: event.organization.externalId,
          source: 'event_dashboard',
        }}
        lead={{
          organisationName: event.organization.name,
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          email: user?.email || '',
        }}
        translations={t('upgrade-dialog', { returnObjects: true })}
      />
    </Suspense>
  );
};

export default UpgradeDialogLoader;
