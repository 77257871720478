import { getAppStoreURL } from '@hopin-team/dashboard-constants';

import {
  EnabledIntegration,
  ExternalAppsInitResponse,
  ExternalIntegration,
  IntegrationFieldValue,
  IntegrationFormValues,
} from './types';

export const getAppStoreWebUrl = ({
  organizationExternalId,
  eventArea,
}: {
  organizationExternalId?: string;
  eventArea: string;
}) => {
  const organizationParameter = organizationExternalId
    ? `/org/${organizationExternalId}`
    : '';
  return `${getAppStoreURL()}${organizationParameter}${eventArea}`;
};

export const getEventAreaParameter = ({ venue }: { venue: string }) => {
  switch (venue) {
    case 'roundtables':
      return '/event_areas/sessions';
    case 'vendors':
      return '/event_areas/booths';
    case 'backstages':
      return '/event_areas/stages';
    case 'events':
      return '/event_areas/reception';
    case 'app_area':
      return '/event_areas/app_area';
    case 'management':
      return '/event_areas/management';
    default:
      return '';
  }
};

export const getExternalIntegrationDefaults = (
  externalIntegrations?: ExternalAppsInitResponse[],
  breakout_apps?: string[],
  enableBreakoutApps?: boolean,
  breakout_label?: string,
) =>
  externalIntegrations?.reduce<ExternalIntegration>(
    (transformedData, integration) => {
      const { name, id, post_configuration } = integration;

      if (!post_configuration.length) {
        return {};
      }

      const integrationFields = post_configuration.reduce((fields, config) => {
        const key = Object.keys(config)[0];
        const value = config[key];
        const currentConfig = integration.current_configuration?.[key];

        const isBoolean = value.type === 'boolean';
        const defaultValue = isBoolean ? 0 : '';
        const fieldValue: string | number =
          currentConfig !== undefined
            ? isBoolean
              ? parseInt(currentConfig)
              : currentConfig
            : defaultValue;

        fields[key] = {
          label: value.label,
          value: fieldValue,
          ...(value.type && { type: value.type }),
        };

        return fields;
      }, {} as { [key: string]: IntegrationFieldValue });

      transformedData[id] = {
        field: { label: name },
        ...integrationFields,
      };

      if (enableBreakoutApps) {
        transformedData[id] = {
          enabled_breakout_room: {
            label: breakout_label || 'Enabled in breakout rooms',
            value: breakout_apps?.includes(id) ? 1 : 0,
            type: 'boolean',
          },
          ...transformedData[id],
        };
      }

      return transformedData;
    },
    {},
  );

export const getEnabledIntegrationDefaults = (
  enabledIntegrations?: string[],
  values?: { name: string; id: number; details: Record<string, string> }[],
  breakout_integrations?: string[],
  enableBreakoutRoom?: boolean,
) => {
  const validIntegrations = [
    'twitter',
    'slido',
    'sidepanel_embed',
    'typeform',
    'go_fund_me',
    'validar',
    'kahoot',
    'interprefy',
    'drift',
  ];

  const enabled: { [key: string]: { [key: string]: string | number } } = {};
  enabledIntegrations?.forEach(integration => {
    const valuesForIntegration = values?.find(
      val => val.name === integration,
    )?.details;
    if (validIntegrations.includes(integration)) {
      enabled[`${integration}_integration_attributes`] = {};

      if (enableBreakoutRoom) {
        enabled[`${integration}_integration_attributes`].enabled_breakout_room =
          breakout_integrations?.includes(integration) ? 1 : 0;
      }

      switch (integration) {
        case 'slido':
          enabled[`${integration}_integration_attributes`].name =
            valuesForIntegration?.name || '';
          enabled[`${integration}_integration_attributes`].app_id =
            valuesForIntegration?.app_id || '';
          break;
        case 'sidepanel_embed':
        case 'typeform':
        case 'go_fund_me':
          enabled[`${integration}_integration_attributes`].name =
            valuesForIntegration?.name || '';
          enabled[`${integration}_integration_attributes`].url =
            valuesForIntegration?.url || '';
          break;
        case 'validar':
          enabled[`${integration}_integration_attributes`].name =
            valuesForIntegration?.name || '';
          enabled[`${integration}_integration_attributes`].url =
            valuesForIntegration?.url || '';
          enabled[`${integration}_integration_attributes`].embed_type =
            valuesForIntegration?.embed_type || '';
          break;
        case 'kahoot':
          enabled[`${integration}_integration_attributes`].name =
            valuesForIntegration?.name || '';
          enabled[`${integration}_integration_attributes`].url =
            valuesForIntegration?.url || 'https://kahoot.it';
          enabled[`${integration}_integration_attributes`].active =
            valuesForIntegration?.active || 0;
          break;
        default:
          enabled[`${integration}_integration_attributes`].app_id =
            valuesForIntegration?.app_id || '';
      }
    }
  });

  return enabled;
};

type FormattedExternalIntegration = {
  [key: string]: { [key: string]: string | number };
};

export const formatExternalIntegrationsPayload = (
  externalIntegrations?: ExternalIntegration,
  copySessionIntegrations?: boolean,
): FormattedExternalIntegration => {
  const formattedData: FormattedExternalIntegration = {};

  if (!externalIntegrations) return {};

  Object.entries(externalIntegrations).forEach(([key, value]) => {
    const formattedIntegration: { [key: string]: string | number } = {};

    Object.entries(value).forEach(([nestedKey, nestedValue]) => {
      if (
        nestedKey === 'field' ||
        (copySessionIntegrations && nestedKey === 'enabled_breakout_room')
      )
        return;
      if (
        typeof nestedValue.value !== 'string' &&
        typeof nestedValue.value !== 'number'
      )
        return;

      formattedIntegration[nestedKey] = nestedValue.value;
    });

    formattedData[key] = formattedIntegration;
  });

  return formattedData;
};

export const getBreakoutExternalIntegration = (
  externalIntegrations?: ExternalIntegration,
): string[] => {
  if (!externalIntegrations) return [];

  return Object.entries(externalIntegrations)
    .filter(([, value]) =>
      Object.entries(value).some(
        ([nestedKey, nestedValue]) =>
          nestedKey === 'enabled_breakout_room' && nestedValue.value === 1,
      ),
    )
    .map(([key]) => key);
};

export const formatEnabledIntegrationsPayload = (
  enabledIntegrations?: EnabledIntegration,
): {
  formattedEnabledIntegrations: EnabledIntegration;
  breakoutEnabledIntegrations: string[];
} => {
  if (!enabledIntegrations)
    return {
      formattedEnabledIntegrations: {},
      breakoutEnabledIntegrations: [],
    };

  const breakoutEnabledIntegrations: string[] = [];

  const formattedEnabledIntegrations = Object.entries(
    enabledIntegrations,
  ).reduce((acc, [key, value]) => {
    const { enabled_breakout_room, ...cleanedValue } = value;

    if (enabled_breakout_room === 1) {
      breakoutEnabledIntegrations.push(
        key.replace('_integration_attributes', ''),
      );
    }

    if (Object.keys(cleanedValue).length > 0) {
      acc[key] = cleanedValue;
    }

    return acc;
  }, {} as EnabledIntegration);

  return { formattedEnabledIntegrations, breakoutEnabledIntegrations };
};

export const integrationsValidationErrorMap: Record<string, string> = {
  'is too long (maximum is 16 characters)': 'integrations.validation.too-long',
  "can't be blank": 'integrations.validation.required',
  'Invalid Slido ID': 'integrations.validation.invalid-slido-id',
  'Invalid GoFundMe URL': 'integrations.validation.invalid-gofundme-url',
  'Invalid twitter URL or handle':
    'integrations.validation.invalid-twitter-url',
  'Invalid Typeform URL': 'integrations.validation.invalid-typeform-url',
  'Validar URL should be in the format':
    'integrations.validation.invalid-validar-url',
  'Invalid Interprefy URL': 'integrations.validation.invalid-interprefy-url',
};

export const formatIntegrationValidationErrors = (
  field: string,
  fieldErrors: string[],
  setError: (field: keyof IntegrationFormValues, error: any) => void,
) => {
  const fieldName = field.replace('_integration', '_integration_attributes');

  const matchingKey = Object.keys(integrationsValidationErrorMap).find(key =>
    fieldErrors[0].includes(key),
  );
  const errorMessage = matchingKey
    ? integrationsValidationErrorMap[matchingKey]
    : 'integrations.validation.invalid';

  setError(`enabledIntegrations[${fieldName}]` as keyof IntegrationFormValues, {
    message: errorMessage,
  });
};
