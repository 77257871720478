import {
  createDashboardReactiveMap,
  DashboardReactiveMapContext,
  DashboardReactiveMapData,
} from '@hopin-team/dashboard-shared';
import { DashboardReactiveMapProvider } from '@hopin-team/dashboard-shared-react';
import { ReactiveMap } from '@slicesjs/reactive-map';
import { useRef } from 'react';

import { getSlicesCDNUrl } from '@/utils/slices.config';

import { useRootSelector } from '../store';
import { getEvent } from '../store/selectors';
import { MountSlice } from './slices/MountSlice';

const PACKAGE_NAME = '@hopin-team/hopin-web-slices-help-center-organizer';
const FE_SLICES_CDN = getSlicesCDNUrl();

export function HelpCenter() {
  const event = useRootSelector(getEvent);

  const reactiveMap = useRef<
    ReactiveMap<DashboardReactiveMapData, DashboardReactiveMapContext>
  >(
    createDashboardReactiveMap({
      clientPackageDetails: `${PACKAGE_NAME}/latest`,
      onAsyncError: () => null,
    }),
  );

  reactiveMap.current.initialise('event_id', () =>
    Promise.resolve(`${event?.id}`),
  );
  reactiveMap.current.initialise('org_id', () =>
    Promise.resolve(`${event?.organization.id}`),
  );

  return (
    <DashboardReactiveMapProvider
      origin={FE_SLICES_CDN}
      reactiveMap={reactiveMap.current}
    >
      <MountSlice
        packageName={PACKAGE_NAME}
        reactiveMap={reactiveMap.current}
        version="latest"
      />
    </DashboardReactiveMapProvider>
  );
}

HelpCenter.displayName = 'HelpCenter';
