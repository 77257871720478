/**
 * Here we create the single application reducer. Partitioning state is not a good idea as it means your reducers can do less
 * with the existing state unless for example the component retrieves that state to then supply in the action payload.
 */
import { utcToZonedTime } from 'date-fns-tz';
import { produce } from 'immer';

import {
  getEnabledIntegrationDefaults,
  getExternalIntegrationDefaults,
} from '@/components/integrations';
import {
  timezonesMap,
  tzList,
} from '@/components/timezone-picker/timezones-map';
import { emailTemplatesReducer } from '@/pages/emails/reducer';
import { initialEmailsState } from '@/pages/emails/state';
import { mapRecordingResponse } from '@/pages/manage-recordings/utils';
import { EventLinksIncluded } from '@/pages/overview/domain';
import {
  EventDetailsSaveFieldName,
  EventSettingsSaveFieldName,
} from '@/pages/registration-page/domain';
import {
  FLAG_COPY_SESSION_INTEGRATIONS,
  FLAG_VIDEO_SESSION_STUDIO,
} from '@/utils/flags';

import { Dataset, Datasets } from './analytics/domain';
import { RootEvent } from './event';
import { RootState } from './state';

const defaultState: RootState = {
  auth: null,
  event: null,
  user: null,
  flags: null,
  pusher: null,
  navigationView: null,
  platformUrls: null,
  stripeUrl: null,
  newRegistrationsDashboardEnabled: null,
  newRegistrationsUrl: null,
  isImpersonating: false,
  sessions: {
    eventPartId: null,
    sessionList: null,
    tags: [],
    tickets: [],
    filters: {
      date: null,
      search: null,
      tags: [],
      tickets: [],
    },
    numberOfSessions: 0,
    page: 1,
    selectedSessionIds: [],
    selectAllPages: false,
    deleteConfirmationShown: false,
    attendeeOptions: [],
    isLoadingAttendeeSearch: false,
    isLoadingSessionList: true,
    isLoadingDeletion: false,
    isLoadingDuplicate: false,
    isLoadingSessionCreation: false,
    sessionForm: {
      isLoading: false,
      data: null,
    },
    bulkEdit: {
      data: null,
      isLoading: false,
      pollingBid: null,
      pollingPending: 0,
      pollingTotal: 0,
    },
  },
  stages: {
    eventStages: 0,
    stageLimitReached: false,
    stageList: [],
    stageListLoading: true,
    stageDeleteLoading: false,
    stageForm: {
      isLoading: false,
      isLoadingStageCreation: false,
      data: null,
    },
  },
  utmCodes: {
    utmCodesList: null,
    listLoadingStatus: 'initial',
    codeForDeletion: null,
    isLoadingDeletion: false,
    editorOpen: false,
  },
  design: {
    isLoading: false,
    theme: null,
    logo: null,
    bannerUrl: null,
    canUseAdvancedMode: false,
    enforceMaxBannerSize: false,
    customBrandingEnabled: false,
    v2HeaderEnabled: false,
    presets: [],
    isPresetCreationMode: false,
    isPresetSaveLoading: false,
    newPresetId: null,
  },
  manageRecordings: {
    recordings: {
      isLoading: {},
      savedRecordings: {},
      isLoadingMyUploads: false,
      myUploads: [],
    },
    recordingGroups: {
      isLoading: false,
      recordingsUploadEnabled: false,
      videoAreas: [],
    },
    viewRecording: {
      isLoading: false,
      isDeleteLoading: false,
      isEditLoading: false,
      isDeleteConfirmationShowing: false,
      isEditPanelShowing: false,
      recording: null,
    },
    virtualSpaces: {
      query: null,
      data: [],
      isLoading: false,
    },
    uploadRecording: {
      completed: false,
      progress: 0,
      isUploading: false,
    },
  },
  overview: {
    isLoading: false,
    eventLinks: {},
    backstageLinks: {},
    isLoadingRtmpSetup: false,
    setupChecklist: null,
    isLoadingSetupChecklist: false,
    isPublishing: false,
    timeChart: {
      data: {},
    },
  },
  setup: {
    isLoading: true,
    isFormSubmitting: false,
    generateRtmpIsLoading: false,
    activityPanel: {
      chat: {
        isLoading: false,
      },
    },
    formValues: null,
  },
  analytics: {
    authentication: null,
    datasets: {},
  },
  metrics: {
    isLoading: true,
    isAnalyticsLoading: true,
    data: {
      total_chat_messages: 0,
      total_networking_meetings: 0,
      total_connection_requests: 0,
      total_scheduled_meetings: 0,
      total_questions_asked: 0,
      total_poll_votes: 0,
      sessions_joined: 0,
      chat_reactions: 0,
      video_reactions: 0,
      confetti: 0,
    },
  },
  engagedAttendees: {
    isLoading: true,
    data: [],
    count: 0,
    page: 0,
  },
  connections: {
    isLoading: true,
    data: { total_connection_requests: 0, total_connections_made: 0 },
  },
  topAttendees: {
    isLoading: true,
    data: [],
  },
  topSponsors: {
    isLoading: true,
    data: [],
  },
  latestConnections: {
    isLoading: true,
    data: [],
  },
  messagesOvertime: {
    isLoading: true,
    data: { summary: { title: '', number: 0 }, chartData: [] },
  },
  meetingsOvertime: {
    isLoading: true,
    data: { summary: { title: '', number: 0 }, chartData: [] },
  },
  report: {
    error: null,
  },
  eventLandingPage: {
    landingPage: {
      loadingStatus: 'initial',
      pageId: null,
      hasCustomLayoutContent: false,
      selectedLayout: 'default',
    },
    widgets: {
      listOpen: false,
      editorOpen: false,
      editorItemId: null,
      list: null,
      listLoadingStatus: 'initial',
    },
    eventDetails: {
      loadingStatus: 'initial',
      event: null,
      saveTransaction: {
        [EventDetailsSaveFieldName.LinkedinCompanyUrl]: {
          status: 'initial',
        },
        [EventDetailsSaveFieldName.LinkedinCompanyUrlEnabled]: {
          status: 'initial',
        },
        [EventDetailsSaveFieldName.LinkedinCommunityBuilderEnabled]: {
          status: 'initial',
        },
        [EventDetailsSaveFieldName.LinkedinEventID]: {
          status: 'initial',
        },
      },
    },
    eventSettings: {
      loadingStatus: 'initial',
      settings: null,
      saveTransaction: {
        [EventSettingsSaveFieldName.RegistrationCloseTime]: {
          status: 'initial',
        },
        [EventSettingsSaveFieldName.RegistrationCloseMessage]: {
          status: 'initial',
        },
        [EventSettingsSaveFieldName.RequireSSOLogin]: {
          status: 'initial',
        },
        [EventSettingsSaveFieldName.DomainSettingsEnabled]: {
          status: 'initial',
        },
        [EventSettingsSaveFieldName.DomainSettingsType]: {
          status: 'initial',
        },
        [EventSettingsSaveFieldName.Domains]: {
          status: 'initial',
        },
        [EventSettingsSaveFieldName.MaxTicketsPerOrder]: {
          status: 'initial',
        },
        [EventSettingsSaveFieldName.Currency]: {
          status: 'initial',
        },
        [EventSettingsSaveFieldName.ConfirmationCustomMessage]: {
          status: 'initial',
        },
        [EventSettingsSaveFieldName.ConfirmationCustomTitle]: {
          status: 'initial',
        },
      },
    },
    currencies: {
      prioritized: [],
      all: [],
    },
  },
  tickets: null,
  upgradeDialogOpen: false,
  marketing: {
    integrationsList: [],
    sharingSettings: { sharingSnippet: '' },
    resetSharingSnippetStatus: 'initial',
    loadingStatus: 'initial',
    editorOpen: false,
    editorItemProvider: null,
    deleteModalOpen: false,
    deleteModalProvider: null,
    isLoadingDeletion: false,
  },
  customDomains: {
    id: null,
    registrationURL: '',
    userVisible: false,
    faviconURL: null,
    statusLastUpdatedAt: null,
    expiresAt: null,
    customDomainsAvailable: null,
    app: {
      domain: '',
      dnsRecords: [],
      hostNameStatus: null,
      hostNameStatusErrors: [],
      sslStatus: null,
      sslStatusErrors: [],
    },
    domain: {
      domain: '',
      dnsRecords: [],
      hostNameStatus: null,
      hostNameStatusErrors: [],
      sslStatus: null,
      sslStatusErrors: [],
    },
  },
  ...initialEmailsState,
};

export const reducer = (
  state: RootState = defaultState,
  event: RootEvent,
): RootState =>
  event
    ? produce(state, draft => {
        emailTemplatesReducer(draft, event);
        switch (event.type) {
          case 'root effect - retrieved token': {
            draft.auth = event.payload;
            break;
          }
          case 'root effect - flags loaded': {
            draft.flags = event.payload.flags;
            break;
          }
          case 'root effect - pusher connected': {
            draft.pusher = event.payload.pusher;
            break;
          }
          case 'loaded dashboard init data': {
            const features =
              event.payload.event.organization.current_payment_plan.features;

            draft.event = {
              address: event.payload.event.address && {
                name: event.payload.event.address.name,
                formatted: event.payload.event.address.formatted,
                venueName: event.payload.event.address.venue_name,
              },
              anonymised: event.payload.event.anonymised,
              areas: event.payload.event.areas,
              draft: event.payload.event.draft,
              eventWizardTemplate: event.payload.event.event_wizard_template,
              externalId: event.payload.event.external_id,
              finished: event.payload.event.finished,
              hasTickets: event.payload.event.has_tickets,
              id: event.payload.event.id,
              isLite: event.payload.event.is_lite,
              maxPrice: event.payload.event.max_price,
              name: event.payload.event.name,
              description: event.payload.event.description,
              createdAt: event.payload.event.created_at,
              organization: {
                name: event.payload.event.organization.name,
                currentPaymentPlan: {
                  name: event.payload.event.organization.current_payment_plan
                    .name,
                  features: {
                    magicLinks: features.magic_links,
                    customEmails: features.custom_emails,
                    customText: features.custom_text,
                    customRegistrationFields:
                      features.custom_registration_fields,
                    hostDefinitions: features.host_definitions,
                    customDomains: features.custom_domains,
                    onsiteHybridEvents: features.onsite_hybrid_events,
                    onsiteAdvancedAddOn: features.onsite_advanced_add_on,
                    advancedBranding: features.advanced_branding,
                    customBranding: features.custom_branding,
                    sendAttendeesEmails: features.send_attendees_emails,
                    analytics: features.analytics,
                    amazon_ivs: features.amazon_ivs,
                    app_store: features.app_store,
                    download_redeem_codes: features.download_redeem_codes,
                    emails_hopin_branding: features.emails_hopin_branding,
                    embeddable_schedules: features.embeddable_schedules,
                    enterprise_sso: features.enterprise_sso,
                    guided_events: features.guided_events,
                    host_definitions: features.host_definitions,
                    magic_links: features.magic_links,
                    rtmp_hidden: features.rtmp_hidden,
                    roles_and_permissions: features.roles_and_permissions,
                    events_lite: features.events_lite,
                    events_pro: features.events_pro,
                    event_dashboard_people_menu:
                      features.event_dashboard_people_menu,
                    custom_segments: features.custom_segments,
                    publish_event: features.publish_event,
                    sessions_recording_with_engagement:
                      features.sessions_recording_with_engagement,
                    stage_caption_translations:
                      features.stage_caption_translations,
                  },
                  recordingsSize:
                    event.payload.event.organization.current_payment_plan
                      .recordings_size,
                  maxAttendees:
                    event.payload.event.organization.current_payment_plan
                      .max_attendees,
                },
                externalId: event.payload.event.organization.external_id,
                hasAnalytics: event.payload.event.organization.has_analytics,
                id: event.payload.event.organization.id,
                maxStages: event.payload.event.organization.max_stages,
                merchantId: event.payload.event.organization.merchant_id,
              },
              picture: event.payload.event.picture,
              primaryBackstageId: event.payload.event.primary_backstage_id,
              session: event.payload.event.session
                ? {
                    id: event.payload.event.session.id,
                    eventPartId: event.payload.event.session.event_part_id,
                  }
                : null,
              slug: event.payload.event.slug,
              status: event.payload.event.status,
              phase: event.payload.event.phase,
              started: event.payload.event.started,
              startingNow: event.payload.event.starting_now,
              timeEndLocal: event.payload.event.time_end_local,
              timeStartLocal: event.payload.event.time_start_local,
              timezone: event.payload.event.timezone,
              validForDeletion: event.payload.event.valid_for_deletion,
              validForDuplication: event.payload.event.valid_for_duplication,
              venueType: event.payload.event.venue_type,
              currency: event.payload.event.currency,
              hasMagicLinks: event.payload.event.has_magic_links,
              hasPromoCodes: event.payload.event.has_promo_codes,
              onlyFreeTickets: event.payload.event.only_free_tickets,
              eventKind: event.payload.event.event_kind,
              registrationCloseTime: new Date(
                event.payload.event.registration_close_time,
              ),
            };
            draft.user = {
              email: event.payload.user.email,
              firstName: event.payload.user.first_name,
              lastName: event.payload.user.last_name,
              picture: event.payload.user.picture,
              id: event.payload.user.id,
              externalId: event.payload.user.external_id,
              confirmed: event.payload.user.confirmed,
              organizations: event.payload.user.organizations.map(
                ({ external_id, ...props }) => ({
                  ...props,
                  externalId: external_id,
                }),
              ),
            };
            draft.navigationView = event.payload.navigation_view;
            draft.newRegistrationsDashboardEnabled =
              event.payload.new_registrations_dashboard_enabled;
            draft.newRegistrationsUrl = event.payload.new_registrations_url;
            draft.isImpersonating = event.payload.impersonating;
            draft.platformUrls = event.payload.platform_urls;
            draft.stripeUrl = event.payload.stripe_url;
            break;
          }
          case 'navigation view set': {
            draft.navigationView = event.payload.view;
            break;
          }
          case 'sessions listing sessions fetch': {
            draft.sessions.numberOfSessions = 0;
            draft.sessions.isLoadingSessionList = true;
            break;
          }
          case 'sessions listing session fetch success': {
            draft.sessions.sessionList = event.payload.roundtables.map(rt => ({
              boothSize: rt.booth_size,
              canSit:
                draft.flags?.[FLAG_VIDEO_SESSION_STUDIO] && rt.studio_enabled
                  ? 'studio'
                  : rt.can_sit,
              canWatch: rt.can_watch,
              description: rt.description,
              eventPartId: rt.event_part_id,
              id: rt.id,
              maxParticipants: rt.max_participants,
              name: rt.name,
              picture: rt.picture,
              priority: rt.priority,
              roundtableUrl: rt.roundtable_url,
              rtmpEnabled: rt.rtmp_enabled,
              uuid: rt.uuid,
            }));
            draft.sessions.eventPartId = event.payload.event_part_id;
            draft.sessions.numberOfSessions =
              event.payload.roundtables_total_count;
            draft.sessions.tags = event.payload.tags;
            draft.sessions.tickets = event.payload.tickets;
            draft.sessions.isLoadingSessionList = false;
            break;
          }
          case 'sessions listing set page': {
            draft.sessions.selectedSessionIds = [];
            draft.sessions.selectAllPages = false;
            draft.sessions.page = event.payload.page;
            break;
          }
          case 'sessions listing page unmounted': {
            draft.sessions = defaultState.sessions;
            break;
          }
          case 'sessions listing ticket filter changed': {
            draft.sessions.filters.tickets = event.payload.tickets;
            draft.sessions.selectedSessionIds = [];
            draft.sessions.page = 1;
            draft.sessions.isLoadingSessionList = true;
            break;
          }
          case 'sessions listing tag filter changed': {
            draft.sessions.filters.tags = event.payload.tags;
            draft.sessions.selectedSessionIds = [];
            draft.sessions.page = 1;
            draft.sessions.isLoadingSessionList = true;
            break;
          }
          case 'sessions listing date filter changed': {
            draft.sessions.filters.date = event.payload.date;
            draft.sessions.selectedSessionIds = [];
            draft.sessions.page = 1;
            draft.sessions.isLoadingSessionList = true;
            break;
          }
          case 'sessions listing search filter changed': {
            draft.sessions.filters.search = event.payload.search;
            draft.sessions.selectedSessionIds = [];
            draft.sessions.page = 1;
            draft.sessions.isLoadingSessionList = true;
            break;
          }
          case 'sessions listing row checkbox clicked': {
            const index = draft.sessions.selectedSessionIds.findIndex(
              id => id === event.payload.selectedSessionId,
            );

            if (index === -1) {
              draft.sessions.selectedSessionIds.push(
                event.payload.selectedSessionId,
              );
            } else {
              draft.sessions.selectedSessionIds.splice(index, 1);
            }
            draft.sessions.selectAllPages = false;
            break;
          }
          case 'sessions listing select all checkbox clicked': {
            const allIds =
              draft.sessions.sessionList?.map(session => session.id) ?? [];

            if (event.payload.selectAll) {
              draft.sessions.selectedSessionIds = allIds;
            } else {
              draft.sessions.selectedSessionIds = [];
            }
            break;
          }
          case 'sessions listing delete session clicked': {
            if (event.payload?.sessionId) {
              draft.sessions.selectedSessionIds = [event.payload?.sessionId];
            }
            draft.sessions.deleteConfirmationShown = true;
            break;
          }
          case 'sessions listing delete confirmation dismissed': {
            draft.sessions.deleteConfirmationShown = false;
            break;
          }
          case 'sessions listing delete failure': {
            draft.sessions.isLoadingDeletion = false;
            break;
          }
          case 'sessions listing delete success': {
            draft.sessions.isLoadingDeletion = false;
            draft.sessions.selectedSessionIds = [];
            draft.sessions.deleteConfirmationShown = false;
            break;
          }
          case 'sessions listing duplicate session clicked': {
            draft.sessions.isLoadingDuplicate = true;
            break;
          }
          case 'sessions listing duplicate session success':
          case 'sessions listing duplicate session failure': {
            draft.sessions.isLoadingDuplicate = false;
            break;
          }
          case 'sessions listing confirm select all pages': {
            draft.sessions.selectAllPages = true;
            break;
          }
          case 'session form submitted': {
            draft.sessions.isLoadingSessionCreation = true;
            break;
          }
          case 'session form save complete': {
            draft.sessions.isLoadingSessionCreation = false;
            break;
          }
          case 'session form submit error': {
            draft.sessions.isLoadingSessionCreation = false;

            if (event.payload.errors) {
              draft.sessions.sessionForm.errors = event.payload.errors;
            }
            break;
          }
          case 'session form attendee search': {
            draft.sessions.isLoadingAttendeeSearch = true;
            break;
          }
          case 'session form attendee search success': {
            draft.sessions.attendeeOptions = event.payload.attendees;
            draft.sessions.isLoadingAttendeeSearch = false;
            break;
          }
          case 'session form attendee search failure': {
            draft.sessions.attendeeOptions = [];
            draft.sessions.isLoadingAttendeeSearch = false;
            break;
          }
          case 'session data fetch': {
            draft.sessions.sessionForm.isLoading = true;
            break;
          }
          case 'session data fetch success': {
            draft.sessions.sessionForm.data = {
              tickets: event.payload.tickets,
              tags: event.payload.tags.map(tag => tag.name),
              stages: event.payload.mirrorable_stages,
              sessions: event.payload.mirrorable_roundtables,
              externalIntegrations: event.payload.external_apps,
              enabledIntegrations: event.payload.enabled_integrations,
              roundtable:
                'roundtable' in event.payload ? event.payload.roundtable : null,
            };
            draft.sessions.sessionForm.isLoading = false;
            break;
          }
          case 'session data fetch error': {
            draft.sessions.sessionForm.isLoading = false;
            break;
          }
          case 'session creation page unmounted': {
            draft.sessions.sessionForm.data = null;
            draft.sessions.sessionForm.errors = undefined;
            break;
          }
          case 'sessions bulk editing fetch success': {
            draft.sessions.bulkEdit.data = {
              availableRtmpSessions: event.payload.available_rtmp_sessions,
              availableRtmpStages: event.payload.available_rtmp_stages,
              availableTags: event.payload.available_tags,
              availableTickets: event.payload.available_tickets,
              availableVideoSettings: event.payload.available_video_settings,
              batchId: event.payload.batch_id,
              defaultData: {
                maxParticipants: event.payload.default_data.max_participants,
                rtmpSession: event.payload.default_data.rtmp_session,
                priority: event.payload.default_data.priority,
                record: event.payload.default_data.record,
                rtmp: event.payload.default_data.rtmp,
                rtmpStage: event.payload.default_data.rtmp_stage,
                rtmpTarget: event.payload.default_data.rtmp_target,
                sessionsSchedulability:
                  event.payload.default_data.sessions_schedulability,
                sessionsSize: event.payload.default_data.sessions_size,
                videoSetting: event.payload.default_data.video_setting,
                youtube: event.payload.default_data.youtube,
              },
              eventType: event.payload.event_type,
              groupedSessionType: event.payload.grouped_session_type,
              roundtablesHaveSegments: event.payload.roundtables_have_segments,
              rtmpEnabled: event.payload.rtmp_enabled,
              videoSessionsEnableLayoutV2:
                event.payload.video_sessions_enable_layout_v2,
              videoSettingsEnabled: event.payload.video_settings_enabled,
            };
            break;
          }
          case 'sessions bulk editing submit clicked':
          case 'sessions bulk editing edit all submit clicked': {
            draft.sessions.bulkEdit.isLoading = true;
            break;
          }
          case 'sessions bulk editing poll start': {
            draft.sessions.bulkEdit.pollingBid = event.payload.bid;
            draft.sessions.bulkEdit.pollingPending = 1;
            draft.sessions.bulkEdit.pollingTotal = 1;
            break;
          }
          case 'sessions bulk editing poll update': {
            draft.sessions.bulkEdit.pollingPending = event.payload.pending;
            draft.sessions.bulkEdit.pollingTotal = event.payload.total;
            break;
          }
          case 'sessions bulk editing save complete': {
            draft.sessions.bulkEdit.isLoading = false;
            draft.sessions.bulkEdit.pollingBid = null;
            break;
          }
          case 'utm codes listing fetch': {
            draft.utmCodes.listLoadingStatus = 'pending';
            break;
          }
          case 'utm codes listing fetch success': {
            draft.utmCodes.listLoadingStatus = 'success';
            draft.utmCodes.utmCodesList = event.payload.utmCodes;
            break;
          }
          case 'utm codes listing fetch failure': {
            draft.utmCodes.listLoadingStatus = 'error';
            break;
          }
          case 'utm codes listing delete utm code clicked': {
            draft.utmCodes.codeForDeletion = event.payload.utmCodeId;
            break;
          }
          case 'utm codes listing delete confirmation dismissed': {
            draft.utmCodes.codeForDeletion = null;
            break;
          }
          case 'utm codes listing delete confirmed': {
            draft.utmCodes.isLoadingDeletion = true;
            break;
          }
          case 'utm codes listing delete failure': {
            draft.utmCodes.isLoadingDeletion = false;
            break;
          }
          case 'utm codes listing delete success': {
            draft.utmCodes.utmCodesList =
              draft.utmCodes.utmCodesList?.filter(
                ({ id }) => id !== draft.utmCodes.codeForDeletion,
              ) || [];
            draft.utmCodes.isLoadingDeletion = false;
            draft.utmCodes.codeForDeletion = null;
            break;
          }
          case 'utm codes listing create utm code clicked': {
            draft.utmCodes.editorOpen = true;
            break;
          }
          case 'utm codes editor close clicked': {
            draft.utmCodes.editorOpen = false;
            draft.utmCodes.editorItemId = undefined;
            draft.utmCodes.utmCodeSaveTransaction = undefined;
            break;
          }
          case 'utm codes listing edit utm code clicked': {
            draft.utmCodes.editorOpen = true;
            draft.utmCodes.editorItemId = event.payload.utmCodeId;
            break;
          }
          case 'utm code editor submitted': {
            draft.utmCodes.utmCodeSaveTransaction = {
              status: 'pending',
            };
            break;
          }
          case 'utm code editor save success': {
            draft.utmCodes.utmCodeSaveTransaction = undefined;
            draft.utmCodes.editorOpen = false;
            draft.utmCodes.editorItemId = undefined;
            break;
          }
          case 'utm code editor save failure': {
            draft.utmCodes.utmCodeSaveTransaction = {
              status: 'error',
              errors: event.payload.errors,
            };
            break;
          }
          case 'design page submit form': {
            draft.design.isLoading = true;
            break;
          }
          case 'design page presets fetch success': {
            draft.design.presets = event.payload;
            break;
          }
          case 'design create preset': {
            draft.design.isPresetSaveLoading = true;
            break;
          }
          case 'design create preset failure': {
            draft.design.isPresetSaveLoading = false;
            break;
          }
          case 'design create preset success': {
            draft.design.newPresetId = event.payload.presetId;
            draft.design.isPresetCreationMode = false;
            draft.design.isPresetSaveLoading = false;
            break;
          }
          case 'design theme fetch success':
          case 'design page submit success': {
            if (typeof event.payload.theme === 'string') {
              const parsedTheme = JSON.parse(event.payload.theme);
              draft.design.theme = parsedTheme;

              draft.design.presets = draft.design.presets.map(preset => ({
                ...preset,
                isDefault: preset.id === parsedTheme.themeId,
              }));
            } else {
              draft.design.theme = event.payload.theme;
            }

            draft.design.logo = event.payload.logo_url;
            draft.design.bannerUrl = event.payload.banner_url;
            draft.design.canUseAdvancedMode =
              event.payload.can_use_advanced_mode;
            draft.design.enforceMaxBannerSize =
              event.payload.enforce_max_banner_size;
            draft.design.customBrandingEnabled =
              event.payload.custom_branding_enabled;
            draft.design.v2HeaderEnabled = event.payload.v2_header_enabled;
            draft.design.isLoading = false;
            break;
          }
          case 'design page submit error': {
            draft.design.isLoading = false;
            break;
          }
          case 'design set create preset mode': {
            draft.design.isPresetCreationMode = event.payload;
            break;
          }
          case 'recordings page unmounted': {
            draft.manageRecordings.recordingGroups.videoAreas = [];
            draft.manageRecordings.recordings.isLoading = {};
            draft.manageRecordings.recordings.savedRecordings = {};
            break;
          }
          case 'recordings areas fetch': {
            draft.manageRecordings.recordingGroups.isLoading = true;
            break;
          }
          case 'recordings areas fetch success': {
            draft.manageRecordings.recordingGroups.videoAreas =
              event.payload.video_areas.map(videoArea => ({
                id: videoArea.id,
                name: videoArea.name,
                type: videoArea.type,
              }));
            draft.manageRecordings.recordingGroups.recordingsUploadEnabled =
              event.payload.recordings_upload_enabled;
            draft.manageRecordings.recordingGroups.isLoading = false;
            break;
          }
          case 'recordings update success': {
            const recording = event.payload;
            const area =
              draft.manageRecordings.recordings.savedRecordings[
                recording.event_part_id
              ];
            const indexOfRecording = area.findIndex(
              savedRecording => savedRecording.id === recording.id,
            );

            if (indexOfRecording !== -1) {
              area[indexOfRecording] = mapRecordingResponse(recording);
            }
            break;
          }
          case 'recording upload process update': {
            const updatedRecording = event.payload.recording;
            switch (event.payload.recordingStateKey) {
              case 'savedRecordings': {
                const indexOfRecording =
                  draft.manageRecordings.recordings.savedRecordings[
                    updatedRecording.eventPart.id
                  ].findIndex(
                    savedRecording => savedRecording.id === updatedRecording.id,
                  );
                if (indexOfRecording !== -1) {
                  draft.manageRecordings.recordings.savedRecordings[
                    updatedRecording.eventPart.id
                  ][indexOfRecording] = updatedRecording;
                }
                break;
              }
              case 'myUploads': {
                const indexOfRecording =
                  draft.manageRecordings.recordings.myUploads.findIndex(
                    savedRecording => savedRecording.id === updatedRecording.id,
                  );
                if (indexOfRecording !== -1) {
                  draft.manageRecordings.recordings.myUploads[
                    indexOfRecording
                  ] = updatedRecording;
                }
                break;
              }
            }
            break;
          }
          case 'recordings delete success': {
            const { areaId, recordingId } = event.payload;

            draft.manageRecordings.recordings.savedRecordings[areaId] =
              draft.manageRecordings.recordings.savedRecordings[areaId].filter(
                ({ id }) => id !== recordingId,
              );
            break;
          }
          case 'upload delete success': {
            const { recordingId } = event.payload;

            draft.manageRecordings.recordings.myUploads =
              draft.manageRecordings.recordings.myUploads.filter(
                ({ id }) => id !== recordingId,
              );
            break;
          }
          case 'recordings fetch data for video area': {
            draft.manageRecordings.recordings.isLoading[
              event.payload.videoAreaId
            ] = true;
            break;
          }
          case 'recordings fetch data for video area success': {
            draft.manageRecordings.recordings.savedRecordings[
              event.payload.videoAreaId
            ] = event.payload.data.map(mapRecordingResponse);
            draft.manageRecordings.recordings.isLoading[
              event.payload.videoAreaId
            ] = false;
            break;
          }
          case 'recordings fetch data for my uploads': {
            draft.manageRecordings.recordings.isLoadingMyUploads = true;
            break;
          }
          case 'recordings fetch data for my uploads success': {
            draft.manageRecordings.recordings.myUploads =
              event.payload.data.map(mapRecordingResponse);
            draft.manageRecordings.recordings.isLoadingMyUploads = false;
            break;
          }
          case 'recordings view recording fetch': {
            draft.manageRecordings.viewRecording.isLoading = true;
            break;
          }
          case 'recordings view recording fetch success': {
            const recording = event.payload;
            draft.manageRecordings.viewRecording.recording = {
              ...mapRecordingResponse(recording),
              category: recording.category,
              description: recording.description,
              related: recording.related.map(mapRecordingResponse),
            };
            draft.manageRecordings.viewRecording.isLoading = false;
            break;
          }
          case 'recordings view set delete confirmation visible': {
            draft.manageRecordings.viewRecording.isDeleteConfirmationShowing =
              event.payload;
            break;
          }
          case 'recordings view set edit panel visible': {
            draft.manageRecordings.viewRecording.isEditPanelShowing =
              event.payload;
            break;
          }
          case 'recordings view update recording': {
            draft.manageRecordings.viewRecording.isEditLoading = true;
            break;
          }
          case 'recordings view update recording success': {
            if (draft.manageRecordings.viewRecording.recording) {
              draft.manageRecordings.viewRecording.recording.description =
                event.payload.description;
              draft.manageRecordings.viewRecording.recording.title =
                event.payload.title;
              draft.manageRecordings.viewRecording.recording.published =
                event.payload.published;
            }

            draft.manageRecordings.viewRecording.isEditLoading = false;
            draft.manageRecordings.viewRecording.isEditPanelShowing = false;

            break;
          }
          case 'recordings view delete clicked': {
            draft.manageRecordings.viewRecording.isDeleteLoading = true;
            break;
          }
          case 'recordings view delete recording success': {
            draft.manageRecordings.viewRecording.isDeleteLoading = false;
            draft.manageRecordings.viewRecording.isDeleteConfirmationShowing =
              false;
            break;
          }
          case 'recordings view page unmounted': {
            draft.manageRecordings.viewRecording.recording = null;
            break;
          }
          case 'recordings virtual spaces search': {
            draft.manageRecordings.virtualSpaces.query = event.payload.value;
            draft.manageRecordings.virtualSpaces.isLoading = true;
            break;
          }
          case 'recordings virtual spaces search success': {
            draft.manageRecordings.virtualSpaces.data = event.payload;
            draft.manageRecordings.virtualSpaces.isLoading = false;
            break;
          }
          case 'recordings virtual spaces search error': {
            draft.manageRecordings.virtualSpaces.isLoading = false;
            break;
          }
          case 'upload recording submit form': {
            draft.manageRecordings.uploadRecording.isUploading = true;
            draft.manageRecordings.uploadRecording.completed = false;
            break;
          }
          case 'upload recording submit form success': {
            draft.manageRecordings.uploadRecording.isUploading = false;
            draft.manageRecordings.uploadRecording.completed = true;
            break;
          }
          case 'upload recording submit form reset': {
            draft.manageRecordings.uploadRecording.isUploading = false;
            draft.manageRecordings.uploadRecording.completed = false;
            draft.manageRecordings.uploadRecording.progress = 0;
            break;
          }
          case 'upload recording submit form progress': {
            const progress = Math.trunc(event.payload.value * 100);
            draft.manageRecordings.uploadRecording.progress = progress;
            break;
          }
          case 'stages listing page unmounted': {
            draft.stages = defaultState.stages;
            break;
          }
          case 'stages listing stages fetch': {
            draft.stages.stageListLoading = true;
            break;
          }
          case 'stages listing stages fetch success': {
            draft.stages.stageListLoading = false;
            draft.stages.eventStages = event.payload.event_stages;
            draft.stages.stageLimitReached = event.payload.stage_limit_reached;
            draft.stages.stageList = event.payload.stages.map(stage => ({
              backstageExternalId: stage.backstage_external_id,
              backstageUrl: stage.backstage_url,
              id: stage.id,
              name: stage.name,
              captioned: stage.captioned,
              description: stage.description,
              isPrimary: stage.is_primary,
              streamProvider: stage.stream_provider,
              venueType: stage.venue_type,
            }));
            break;
          }
          case 'stage listing delete stage': {
            draft.stages.stageDeleteLoading = true;
            break;
          }
          case 'stage listing delete stage failure':
          case 'stage listing delete stage success': {
            draft.stages.stageDeleteLoading = false;
            break;
          }
          case 'stage data fetch': {
            draft.stages.stageForm.isLoading = true;
            break;
          }
          case 'stage data fetch success': {
            draft.stages.stageForm.data = {
              tickets: event.payload.tickets,
              externalIntegrations: event.payload.external_apps,
              enabledIntegrations: event.payload.enabled_integrations,
            };
            draft.stages.stageForm.isLoading = false;
            break;
          }
          case 'stage data fetch error': {
            draft.stages.stageForm.isLoading = false;
            break;
          }
          case 'stage form submitted': {
            draft.stages.stageForm.isLoadingStageCreation = true;
            break;
          }
          case 'stage form save complete': {
            draft.stages.stageForm.isLoadingStageCreation = false;
            break;
          }
          case 'stage form submit error': {
            draft.stages.stageForm.isLoadingStageCreation = false;

            if (event.payload.errors) {
              draft.stages.stageForm.errors = event.payload.errors;
            }
            break;
          }
          case 'overview data fetch':
          case 'overview event links fetch': {
            draft.overview.isLoading = true;
            break;
          }
          case 'overview data fetch success': {
            draft.overview.isLoading = false;
            break;
          }
          case 'overview event links fetch success': {
            draft.overview.isLoading = false;

            if (
              !event.payload.data ||
              !event.payload.data.attributes ||
              !event.payload.included
            )
              return;

            const { data } = event.payload;

            draft.overview.eventLinks = {
              id: data.id,
              type: data.type,
              attributes: {
                registrationUrl: data.attributes.registration_url,
              },
              relationships: {
                backstageLinks: data.relationships.backstage_links.data,
              },
              meta: {
                rtmpAllowed: data.meta.rtmp_allowed,
              },
            };

            event.payload.included.forEach((link: EventLinksIncluded) => {
              if (link.attributes) {
                draft.overview.backstageLinks[link.id] = {
                  id: link.id,
                  type: link.type,
                  attributes: {
                    name: link.attributes.name,
                    hopinStudioUrl: link.attributes.hopin_studio_url,
                    stageUrl: link.attributes.stage_url,
                    createRtmpVideoChannelsUrl:
                      link.attributes.create_rtmp_video_channels_url,
                    rtmpCredentials: link.attributes.rtmp_credentials.length
                      ? link.attributes.rtmp_credentials.map(credential => ({
                          label: credential.label,
                          streamKey: credential.stream_key,
                          deliveryType: credential.delivery_type,
                          rtmpSupported: credential.rtmp_supported,
                          rtmpServerUrl: credential.rtmp_server_url,
                          rtmpsSupported: credential.rtmps_supported,
                          rtmpsServerUrl: credential.rtmps_server_url,
                          priority: credential.priority,
                        }))
                      : [],
                  },
                };
              }
            });

            break;
          }
          case 'overview data fetch error': {
            draft.overview.isLoading = false;
            break;
          }
          case 'overview generate rtmp setup': {
            draft.overview.isLoadingRtmpSetup = true;
            break;
          }
          case 'overview generate rtmp setup success':
          case 'overview generate rtmp setup error': {
            draft.overview.isLoadingRtmpSetup = false;
            break;
          }
          case 'overview event setup checklist data fetch': {
            if (event.payload?.showLoader) {
              draft.overview.isLoadingSetupChecklist = true;
            }
            break;
          }
          case 'overview event setup checklist data fetch success':
          case 'overview event setup checklist update step success':
          case 'overview event setup checklist visibility success': {
            // Use snake case for consistency with API
            draft.overview.setupChecklist = {
              id: event.payload.data.id,
              type: event.payload.data.type,
              attributes: {
                visible: event.payload.data.attributes.visible,
                steps: {
                  details: event.payload.data.attributes.steps.details,
                  tickets: event.payload.data.attributes.steps.tickets,
                  landing_page:
                    event.payload.data.attributes.steps.landing_page,
                  reception: event.payload.data.attributes.steps.reception,
                  stage: event.payload.data.attributes.steps.stage,
                  sessions: event.payload.data.attributes.steps.sessions,
                  speaker_profiles:
                    event.payload.data.attributes.steps.speaker_profiles,
                  previewed: event.payload.data.attributes.steps.previewed,
                  published: event.payload.data.attributes.steps.published,
                  registration_form:
                    event.payload.data.attributes.steps.registration_form,
                  branding: event.payload.data.attributes.steps.branding,
                  connect_crm: event.payload.data.attributes.steps.connect_crm,
                  link_shared: event.payload.data.attributes.steps.link_shared,
                  publish_recordings:
                    event.payload.data.attributes.steps.publish_recordings,
                  visit_analytics:
                    event.payload.data.attributes.steps.visit_analytics,
                  generate_report:
                    event.payload.data.attributes.steps.generate_report,
                  check_emails:
                    event.payload.data.attributes.steps.check_emails,
                  duplicate_event:
                    event.payload.data.attributes.steps.duplicate_event,
                },
              },
            };
            draft.overview.isLoadingSetupChecklist = false;
            break;
          }
          case 'overview event setup checklist data fetch error': {
            draft.overview.isLoadingSetupChecklist = false;
            break;
          }
          case 'overview event setup checklist publish button clicked': {
            draft.overview.isPublishing = true;
            break;
          }
          case 'overview event setup checklist publish success':
          case 'overview event setup checklist publish error': {
            draft.overview.isPublishing = false;
            break;
          }
          case 'setup data fetch': {
            draft.setup.isLoading = true;
            break;
          }
          case 'setup data fetch success': {
            // TODO populate session form options from API
            draft.sessions.sessionForm.data = {
              tickets: [{ label: 'Free', id: 1 }],
              tags: [],
              stages: [],
              sessions: [],
              externalIntegrations: [],
              enabledIntegrations: [],
              roundtable: null,
            };

            const payload = event.payload;
            const tz = payload.event.timezone as (typeof tzList)[number];

            draft.setup.formValues = {
              event: {
                name: payload.event.name,
                description: payload.event.description,
                timeStart: utcToZonedTime(
                  payload.event.time_start,
                  timezonesMap[tz],
                ).toISOString(),
                timeEnd: utcToZonedTime(
                  payload.event.time_end,
                  timezonesMap[tz],
                ).toISOString(),
                timezone: payload.event.timezone,
                landingArea: payload.event.landing_area,
                customAreaDetails: {
                  title: payload.event.custom_area_title,
                  icon: payload.event.custom_area_icon_url,
                  icon_local: null,
                  url: payload.event.custom_area_url,
                  newWindow: payload.event.custom_area_new_window,
                },
                venueType: payload.event.venue_type,
                /**
                 * Backend doesn't include eventKind in GET /setup.json
                 * But accepts it on PUT /setup.json
                 */
                eventKind: draft.event?.isLite ? 'lite' : 'regular',
                eventType: payload.event.event_type,
                slug: payload.event.slug,
                eventTemplate: payload.event.event_template,
                extraAttributes: {
                  sessionVisibleBeforeMinutes:
                    payload.event.extra_attributes
                      .session_visible_before_minutes,
                  sessionVisibleAfterMinutes:
                    payload.event.extra_attributes
                      .session_visible_after_minutes,
                },
                recordingEnabled: payload.event.recording_enabled,
                postEventEnabled: payload.event.post_event_enabled,
                postEventDurationAmount:
                  payload.event.post_event_duration_amount || 12,
                postEventDurationUnit:
                  payload.event.post_event_duration_unit || 'months',
              },
              availableEventParts: payload.available_event_parts,
              visibleEventParts: payload.visible_event_parts,
              venue: {
                moderatedQuestions: payload.venue.moderated_questions,
                attendeesVisibility:
                  payload.venue.attendees_visibility === 'show_all',
                sidePanelConfig: {
                  chat: {
                    event: payload.venue.side_panel_config.chat.event,
                    sessions: payload.venue.side_panel_config.chat.sessions,
                    stage: payload.venue.side_panel_config.chat.stage,
                    expo: payload.venue.side_panel_config.chat.expo,
                  },
                  polls: {
                    event: payload.venue.side_panel_config.polls.event,
                    sessions: payload.venue.side_panel_config.polls.sessions,
                    stage: payload.venue.side_panel_config.polls.stage,
                    expo: payload.venue.side_panel_config.polls.expo,
                  },
                  qAndA: {
                    event: payload.venue.side_panel_config['q-and-a'].event,
                    sessions:
                      payload.venue.side_panel_config['q-and-a'].sessions,
                    stage: payload.venue.side_panel_config['q-and-a'].stage,
                    expo: payload.venue.side_panel_config['q-and-a'].expo,
                  },
                },
              },
              stage: payload.stage
                ? {
                    id: payload.stage.id,
                    backstageUrl: payload.stage.backstage_url,
                    ringcentralStudioUrl: payload.stage.ringcentral_studio_url,
                    streamyardStudioUrl: payload.stage.streamyard_studio_url,
                    studioType: payload.stage.studio_type,
                    streamyardDecommissioned:
                      payload.stage.streamyard_decommissioned,
                    rtmpChannels: payload.stage.rtmp_channels.map(channel => ({
                      isPrimary: channel.is_primary,
                      label: channel.label,
                      rtmpHidden: channel.rtmp_hidden,
                      rtmpServerUrl: channel.rtmp_server_url,
                      rtmpsHidden: channel.rtmps_hidden,
                      rtmpsServerUrl: channel.rtmps_server_url,
                      rtmpsStreamKey: channel.rtmps_stream_key,
                    })),
                  }
                : undefined,
              session: payload.session
                ? {
                    canWatch: payload.session.can_watch,
                    canParticipate:
                      draft.flags?.[FLAG_VIDEO_SESSION_STUDIO] &&
                      payload.session.studio_enabled
                        ? 'studio'
                        : payload.session.can_sit,
                    maxParticipants: payload.session.max_participants,
                    moderators: payload.session.moderators.map(p => ({
                      label: p.name,
                      value: p.id.toString(),
                    })),
                    additionalInformation:
                      payload.session.additional_information,
                  }
                : undefined,
              enabledIntegrations: getEnabledIntegrationDefaults(
                payload.enabled_integrations,
                payload.event.enabled_integrations,
                draft.flags?.[FLAG_COPY_SESSION_INTEGRATIONS]
                  ? payload.session?.breakout_integrations
                  : [],
                draft.flags?.[FLAG_COPY_SESSION_INTEGRATIONS] ? true : false,
              ),
              externalIntegrations: getExternalIntegrationDefaults(
                payload.external_apps,
                draft.flags?.[FLAG_COPY_SESSION_INTEGRATIONS]
                  ? payload.session?.breakout_apps
                  : [],
                draft.flags?.[FLAG_COPY_SESSION_INTEGRATIONS] ? true : false,
              ),
            };
            draft.setup.isLoading = false;
            draft.setup.isFormSubmitting = false;
            break;
          }
          case 'setup data fetch error': {
            draft.setup.isLoading = false;
            break;
          }
          case 'setup page submit form': {
            draft.setup.isFormSubmitting = true;
            break;
          }
          case 'setup page submit form success': {
            if (draft.event) {
              draft.event.name = event.payload.event.name;
              draft.event.timeStartLocal = event.payload.event.time_start;
              draft.event.timeEndLocal = event.payload.event.time_end;
              draft.event.timezone = event.payload.event.timezone;
              draft.event.areas = event.payload.event.areas;
            }
            draft.setup.isFormSubmitting = false;
            if (draft.setup.formValues?.stage)
              draft.setup.formValues.stage.streamyardDecommissioned =
                !!event.payload.stage?.streamyardDecommissioned;
            break;
          }
          case 'setup page submit form error': {
            draft.setup.isFormSubmitting = false;

            if (event.payload?.errors) {
              draft.setup.errors = event.payload.errors;
            }
            break;
          }
          case 'analytics authentication success': {
            draft.analytics.authentication = event.payload;
            break;
          }
          case 'analytics dataset fetch': {
            (draft.analytics.datasets[
              event.payload.kind
            ] as Datasets[typeof event.payload.kind]) = {
              status: 'loading',
            };
            break;
          }
          case 'analytics dataset fetch success': {
            (draft.analytics.datasets[event.payload.kind] as Dataset<
              typeof event.payload.data
            >) = {
              status: 'complete',
              data: event.payload.data,
            };
            break;
          }
          case 'analytics dataset fetch error': {
            (draft.analytics.datasets[
              event.payload.kind
            ] as Datasets[typeof event.payload.kind]) = {
              status: 'error',
            };
            break;
          }
          case 'overview analytics time chart date changed': {
            draft.overview.timeChart.data.startTime = event.payload.data[0];
            draft.overview.timeChart.data.endTime = event.payload.data[1];
            break;
          }
          case 'setup page clear chat': {
            draft.setup.activityPanel.chat.isLoading = true;
            break;
          }
          case 'setup page clear chat error':
          case 'setup page clear chat success': {
            draft.setup.activityPanel.chat.isLoading = false;
            break;
          }
          case 'setup page generate RTMP': {
            draft.setup.generateRtmpIsLoading = true;
            break;
          }
          case 'setup page generate RTMP failure': {
            draft.setup.generateRtmpIsLoading = false;
            break;
          }
          case 'setup page generate RTMP success': {
            draft.setup.generateRtmpIsLoading = false;

            if (draft.setup.formValues?.stage) {
              draft.setup.formValues.stage = {
                ...draft.setup.formValues.stage,
                rtmpChannels: event.payload.rtmp_channels.map(channel => ({
                  isPrimary: channel.is_primary,
                  label: channel.label,
                  rtmpHidden: channel.rtmp_hidden,
                  rtmpServerUrl: channel.rtmp_server_url,
                  rtmpsHidden: channel.rtmps_hidden,
                  rtmpsServerUrl: channel.rtmps_server_url,
                  rtmpsStreamKey: channel.rtmps_stream_key,
                })),
              };
            }
            break;
          }
          case 'visible event parts radio group changed': {
            if (draft.setup.formValues) {
              draft.setup.formValues = {
                ...draft.setup.formValues,
                visibleEventParts: event.payload,
              };
            }
            break;
          }
          case 'event tickets fetch success': {
            draft.tickets = event.payload.tickets.map(
              ({ sales_start_time, sales_end_time, ...ticket }) => ({
                ...ticket,
                sales_start_time: sales_start_time
                  ? new Date(sales_start_time)
                  : null,
                sales_end_time: sales_end_time
                  ? new Date(sales_end_time)
                  : null,
              }),
            );
            break;
          }
          case 'event landing page create widget clicked': {
            draft.eventLandingPage.widgets.editorOpen = true;
            break;
          }
          case 'event landing page widget editor close clicked': {
            draft.eventLandingPage.widgets.editorOpen = false;
            draft.eventLandingPage.widgets.editorItemId = null;
            draft.eventLandingPage.widgets.saveTransaction = undefined;
            break;
          }
          case 'event landing page manage widgets clicked': {
            draft.eventLandingPage.widgets.listOpen = true;
            break;
          }
          case 'event landing page manage widgets close clicked': {
            draft.eventLandingPage.widgets.listOpen = false;
            break;
          }
          case 'event landing page widget listing fetch': {
            draft.eventLandingPage.widgets.listLoadingStatus = 'pending';
            break;
          }
          case 'event landing page widget listing fetch success': {
            draft.eventLandingPage.widgets.listLoadingStatus = 'success';
            draft.eventLandingPage.widgets.list = event.payload.widgets;
            break;
          }
          case 'event landing page widget listing fetch failure': {
            draft.eventLandingPage.widgets.listLoadingStatus = 'error';
            break;
          }
          case 'event landing page widget settings clicked': {
            draft.eventLandingPage.widgets.editorOpen = true;
            draft.eventLandingPage.widgets.editorItemId =
              event.payload.widgetId;
            break;
          }
          case 'event landing page widget editor save': {
            draft.eventLandingPage.widgets.saveTransaction = {
              status: 'pending',
            };
            break;
          }
          case 'event landing page widget save success': {
            draft.eventLandingPage.widgets.saveTransaction = {
              status: 'success',
            };
            draft.eventLandingPage.widgets.editorOpen = false;
            draft.eventLandingPage.widgets.editorItemId = null;
            draft.eventLandingPage.widgets.listOpen = true;
            draft.eventLandingPage.widgets.saveTransaction = undefined;

            const { widget } = event.payload;

            const index =
              draft.eventLandingPage.widgets.list?.findIndex(
                w => w.id === widget.id,
              ) || -1;

            if (index > -1 && draft.eventLandingPage.widgets.list) {
              draft.eventLandingPage.widgets.list[index] = widget;
            } else {
              draft.eventLandingPage.widgets.list?.push(widget);
            }

            break;
          }
          case 'event landing page widget save failure': {
            draft.eventLandingPage.widgets.saveTransaction = {
              status: 'error',
              errors: event.payload.errors,
            };
            break;
          }
          case 'event landing page widget delete clicked': {
            draft.eventLandingPage.widgets.deleteTransaction = {
              widgetId: event.payload.widgetId,
              status: 'awaiting-confirmation',
            };
            break;
          }
          case 'event landing page widget delete cancel': {
            draft.eventLandingPage.widgets.deleteTransaction = undefined;
            break;
          }
          case 'event landing page widget delete confirm': {
            draft.eventLandingPage.widgets.deleteTransaction = {
              widgetId: event.payload.widgetId,
              status: 'pending',
            };
            break;
          }
          case 'event landing page widget delete failure':
          case 'event landing page widget delete success': {
            draft.eventLandingPage.widgets.deleteTransaction = undefined;
            break;
          }
          case 'event landing page pages fetch': {
            draft.eventLandingPage.landingPage.loadingStatus = 'pending';
            break;
          }
          case 'event landing page select page success':
          case 'event landing page pages fetch success': {
            draft.eventLandingPage.landingPage.loadingStatus = 'success';

            let pageId: number | null = null;
            let hasCustomLayoutContent = false;
            let selectedLayout: 'default' | 'advanced' = 'default';

            if (event.payload.pages.length) {
              const page = event.payload.pages[0];

              pageId = page.id;
              hasCustomLayoutContent = Boolean(
                (page.content && Object.keys(page.content).length) ||
                  (page.properties && Object.keys(page.properties).length),
              );
              if (page.enabled && hasCustomLayoutContent)
                selectedLayout = 'advanced';
            }

            draft.eventLandingPage.landingPage.pageId = pageId;
            draft.eventLandingPage.landingPage.hasCustomLayoutContent =
              hasCustomLayoutContent;
            draft.eventLandingPage.landingPage.selectedLayout = selectedLayout;

            break;
          }
          case 'event landing page event details fetch': {
            draft.eventLandingPage.eventDetails.loadingStatus = 'pending';
            draft.eventLandingPage.eventDetails.event = null;
            break;
          }
          case 'event landing page event details fetch success': {
            draft.eventLandingPage.eventDetails.loadingStatus = 'success';
            draft.eventLandingPage.eventDetails.event =
              event.payload.eventDetails.event;
            break;
          }
          case 'event landing page event details fetch failure': {
            draft.eventLandingPage.eventDetails.loadingStatus = 'error';
            draft.eventLandingPage.eventDetails.event = null;
            break;
          }
          case 'event landing page event details update save status': {
            draft.eventLandingPage.eventDetails.saveTransaction[
              event.payload.fieldName
            ] = event.payload.status;
            break;
          }
          case 'event landing page event details update details': {
            draft.eventLandingPage.eventDetails.event = event.payload.event;
            break;
          }
          case 'upgrade dialog opened': {
            draft.upgradeDialogOpen = true;
            break;
          }
          case 'upgrade dialog closed': {
            draft.upgradeDialogOpen = false;
            break;
          }
          case 'event landing page event settings fetch initiated': {
            draft.eventLandingPage.eventSettings.loadingStatus = 'pending';
            draft.eventLandingPage.eventSettings.settings = null;
            break;
          }
          case 'event landing page event settings fetch succeeded': {
            draft.eventLandingPage.eventSettings.loadingStatus = 'success';
            draft.eventLandingPage.eventSettings.settings =
              event.payload.eventSettings;
            break;
          }
          case 'event landing page event settings fetch failed': {
            draft.eventLandingPage.eventSettings.loadingStatus = 'error';
            draft.eventLandingPage.eventSettings.settings = null;
            break;
          }
          case 'event landing page event settings save initiated': {
            for (const field of event.payload.fields) {
              draft.eventLandingPage.eventSettings.saveTransaction[field.name] =
                { status: 'pending' };
            }
            break;
          }
          case 'event landing page event settings save succeeded': {
            for (const field of event.payload.fields) {
              draft.eventLandingPage.eventSettings.saveTransaction[field.name] =
                { status: 'success' };
            }
            draft.eventLandingPage.eventSettings.settings =
              event.payload.eventSettings;
            break;
          }
          case 'event landing page event settings save failed': {
            for (const field of event.payload.fields) {
              draft.eventLandingPage.eventSettings.saveTransaction[field.name] =
                { status: 'error', errors: event.payload.errors };
            }
            break;
          }
          case 'event landing page currencies fetch failed': {
            draft.eventLandingPage.currencies = {
              prioritized: [],
              all: [],
            };
            break;
          }
          case 'event landing page currencies fetch succeeded': {
            draft.eventLandingPage.currencies = event.payload.currencies;
            break;
          }
          case 'marketing settings fetch failure': {
            draft.marketing.loadingStatus = 'error';
            break;
          }
          case 'marketing settings fetch success': {
            draft.marketing.loadingStatus = 'success';
            draft.marketing.sharingSettings = {
              sharingSnippet: event.payload.sharing_snippet,
            };
            draft.marketing.integrationsList =
              event.payload.tracking_integrations;
            break;
          }
          case 'marketing sharing settings submitted':
          case 'marketing tracking integration submitted': {
            draft.marketing.settingsSaveTransaction = {
              status: 'pending',
            };
            break;
          }
          case 'marketing sharing settings save success':
          case 'marketing tracking integration save success':
          case 'marketing tracking integration editor close clicked': {
            draft.marketing.editorOpen = false;
            draft.marketing.editorItemProvider = undefined;
            draft.marketing.settingsSaveTransaction = undefined;
            break;
          }
          case 'marketing sharing settings save failure':
          case 'marketing tracking integration save failure': {
            draft.marketing.settingsSaveTransaction = { status: 'failure' };
            break;
          }
          case 'marketing tracking integration add clicked': {
            draft.marketing.editorOpen = true;
            break;
          }
          case 'marketing tracking integration edit clicked': {
            draft.marketing.editorOpen = true;
            draft.marketing.editorItemProvider = event.payload.provider;
            break;
          }
          case 'marketing tracking integration delete clicked': {
            draft.marketing.deleteModalOpen = true;
            draft.marketing.deleteModalProvider = event.payload.provider;
            break;
          }
          case 'marketing tracking integration delete confirm': {
            draft.marketing.isLoadingDeletion = true;
            break;
          }
          case 'marketing tracking integration delete cancelled': {
            draft.marketing.deleteModalOpen = false;
            draft.marketing.deleteModalProvider = null;
            break;
          }
          case 'marketing tracking integration delete success': {
            draft.marketing.deleteModalOpen = false;
            draft.marketing.deleteModalProvider = null;
            draft.marketing.isLoadingDeletion = false;
            break;
          }
          case 'marketing tracking integration delete failure': {
            draft.marketing.isLoadingDeletion = false;
            break;
          }
          case 'marketing reset sharing snippet clicked': {
            draft.marketing.resetSharingSnippetStatus = 'pending-confirmation';
            break;
          }
          case 'marketing reset sharing snippet cancelled': {
            draft.marketing.resetSharingSnippetStatus = 'initial';
            break;
          }
          case 'marketing reset sharing snippet confirmed': {
            draft.marketing.resetSharingSnippetStatus = 'pending';
            break;
          }
          case 'marketing reset sharing snippet failure': {
            draft.marketing.resetSharingSnippetStatus = 'error';
            break;
          }
          case 'marketing reset sharing snippet success': {
            draft.marketing.resetSharingSnippetStatus = 'initial';
            draft.marketing.sharingSettings.sharingSnippet = '';
            break;
          }
          case 'connection key metrics fetch start': {
            draft.metrics.isLoading = true;
            break;
          }
          case 'connection key metrics fetch success': {
            draft.metrics.data = { ...draft.metrics.data, ...event.payload };
            draft.metrics.isLoading = false;
            break;
          }
          case 'connection key metrics fetch failed': {
            draft.metrics.isLoading = false;
            break;
          }
          case 'analytics key metrics fetch start': {
            draft.metrics.isAnalyticsLoading = true;
            break;
          }
          case 'analytics key metrics fetch success': {
            draft.metrics.data = { ...draft.metrics.data, ...event.payload };
            draft.metrics.isAnalyticsLoading = false;
            break;
          }
          case 'analytics key metrics fetch failed': {
            draft.metrics.isAnalyticsLoading = false;
            break;
          }
          case 'engaged attendee fetch start': {
            draft.engagedAttendees.isLoading = true;
            break;
          }
          case 'engaged attendee fetch success': {
            draft.engagedAttendees.isLoading = false;
            if (event.payload.page === 0) {
              draft.engagedAttendees.data = event.payload.items;
              draft.engagedAttendees.count = event.payload.count;
            } else {
              draft.engagedAttendees.data = draft.engagedAttendees.data.concat(
                event.payload.items,
              );
            }
            break;
          }
          case 'engaged attendee fetch failed': {
            draft.engagedAttendees.isLoading = false;
            break;
          }
          case 'engaged attendee page increment': {
            draft.engagedAttendees.page++;
            break;
          }
          case 'connections made fetch start': {
            draft.connections.isLoading = true;
            break;
          }
          case 'connections made fetch success': {
            draft.connections.data = event.payload;
            draft.connections.isLoading = false;
            break;
          }
          case 'connections made fetch failed': {
            draft.connections.isLoading = false;
            break;
          }
          case 'top attendees fetch start': {
            draft.topAttendees.isLoading = true;
            break;
          }
          case 'top attendees fetch success': {
            draft.topAttendees.data = event.payload;
            draft.topAttendees.isLoading = false;
            break;
          }
          case 'top attendees fetch failed': {
            draft.topAttendees.isLoading = false;
            break;
          }
          case 'top sponsors fetch start': {
            draft.topSponsors.isLoading = true;
            break;
          }
          case 'top sponsors fetch success': {
            draft.topSponsors.data = event.payload;
            draft.topSponsors.isLoading = false;
            break;
          }
          case 'top sponsors fetch failed': {
            draft.topSponsors.isLoading = false;
            break;
          }
          case 'latest connections fetch start': {
            draft.latestConnections.isLoading = true;
            break;
          }
          case 'latest connections fetch success': {
            draft.latestConnections.data = event.payload;
            draft.latestConnections.isLoading = false;
            break;
          }
          case 'latest connections fetch failed': {
            draft.latestConnections.isLoading = false;
            break;
          }
          case 'messages overtime fetch start': {
            draft.messagesOvertime.isLoading = true;
            break;
          }
          case 'messages overtime fetch success': {
            draft.messagesOvertime.data = event.payload;
            draft.messagesOvertime.isLoading = false;
            break;
          }
          case 'messages overtime fetch failed': {
            draft.messagesOvertime.isLoading = false;
            break;
          }
          case 'meetings overtime fetch start': {
            draft.meetingsOvertime.isLoading = true;
            break;
          }
          case 'meetings overtime fetch success': {
            draft.meetingsOvertime.data = event.payload;
            draft.meetingsOvertime.isLoading = false;
            break;
          }
          case 'meetings overtime fetch failed': {
            draft.meetingsOvertime.isLoading = false;
            break;
          }
          case 'create report job failed': {
            draft.report.error = event.payload;
            break;
          }
          case 'custom domains settings fetch success': {
            const attrs = event?.payload?.data?.attributes;
            draft.customDomains = {
              id: event?.payload?.data?.id,
              registrationURL: attrs.registration_url,
              userVisible: attrs.user_visible,
              faviconURL: attrs.favicon_url,
              statusLastUpdatedAt: attrs.statuses_last_updated_at,
              expiresAt: attrs.expires_at,
              customDomainsAvailable:
                attrs.organization_available_custom_domains,
              app: {
                domain: attrs.app_domain,
                dnsRecords: attrs.app_domain_dns_records,
                hostNameStatus: attrs.app_domain_hostname_status,
                hostNameStatusErrors: attrs.app_domain_hostname_status_errors,
                sslStatus: attrs.app_domain_ssl_status,
                sslStatusErrors: attrs.app_domain_ssl_status_errors,
              },
              domain: {
                domain: attrs.domain,
                dnsRecords: attrs.domain_dns_records,
                hostNameStatus: attrs.domain_hostname_status,
                hostNameStatusErrors: attrs.domain_hostname_status_errors,
                sslStatus: attrs.domain_ssl_status,
                sslStatusErrors: attrs.domain_ssl_status_errors,
              },
            };
            break;
          }
        }
      })
    : state;
