/* eslint-disable testing-library/render-result-naming-convention */
import { useTranslation } from '@hopin-team/provider-translation';
import cx from 'classnames';
import {
  forwardRef,
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  PropsWithChildren,
  PropsWithoutRef,
  RefAttributes,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Checkbox } from '@/components/checkbox/checkbox';

import { ExclamationCircleIcon } from '../icons';
import { Pill } from '../Pill';
import { IntegrationFormValues } from './types';

export type ExternalIntegrationUIType = {
  Card: (
    props: PropsWithChildren<{
      title: string;
      renderToggleSwitch?: () => JSX.Element;
      toggleSwitchLabel?: string;
    }>,
  ) => JSX.Element;
  Label: (props: PropsWithChildren<{ title: string }>) => JSX.Element;
  Input: ForwardRefExoticComponent<
    PropsWithoutRef<
      {
        hasErrors?: boolean;
        className?: string;
      } & InputHTMLAttributes<HTMLInputElement>
    > &
      RefAttributes<HTMLInputElement>
  >;
  ErrorMessage: (props: { message: string }) => JSX.Element;
  Toggle: typeof Checkbox | typeof Pill;
};

const defaultAppUI: ExternalIntegrationUIType = {
  Input: forwardRef(function Input(props, ref) {
    const { className, hasErrors = false, ...rest } = props;
    return (
      <input
        ref={ref}
        placeholder={props.placeholder}
        className={cx(
          'font-inter placeholder:text-gray-650 mt-2 h-full w-full rounded border border-gray-300 px-4 py-2 text-sm text-gray-800',
          className,
          {
            'border-red-500': hasErrors,
          },
        )}
        type="text"
        {...rest}
      />
    );
  }),
  ErrorMessage: props => (
    <div className="mt-2 flex text-xs text-red-500">
      <ExclamationCircleIcon className="min-h-[1rem] min-w-[1rem]" />
      <p className="ml-1">{props.message}</p>
    </div>
  ),
  Label: props => (
    <label className="font-inter text-gray-650 text-xs">
      {props.title}
      {props.children}
    </label>
  ),
  Toggle: (props: any) => (
    <div className="mt-2">
      <Checkbox {...props} />
    </div>
  ),
  Card: props => {
    const ToggleView = props.renderToggleSwitch;
    const Label = defaultAppUI.Label;

    return (
      <div className="mb-6">
        <p className="text-xs font-medium text-gray-800">{props.title}</p>
        <div className="flex items-baseline gap-2">
          {props.children}
          {ToggleView && (
            <div className="h-full">
              <Label title={props.toggleSwitchLabel as string}>
                <ToggleView />
              </Label>
            </div>
          )}
        </div>
      </div>
    );
  },
};

type ExternalIntegrationsProps = {
  AppUI?: ExternalIntegrationUIType;
  enableBreakoutApps?: boolean;
};

export const ExternalIntegrations = ({
  AppUI = defaultAppUI,
  enableBreakoutApps = true,
}: ExternalIntegrationsProps) => {
  const { t } = useTranslation();
  const { register, control, getValues, formState } =
    useFormContext<IntegrationFormValues>();

  const externalApps = getValues('externalIntegrations');
  const errors = formState.errors.externalIntegrations;

  if (!externalApps) return null;

  return (
    <>
      {Object.entries(externalApps).map(([key, config]) => (
        <AppUI.Card
          key={key}
          title={config.field.label}
          toggleSwitchLabel={t('integrations.enabled')}
          renderToggleSwitch={() => (
            <Controller
              control={control}
              name={
                `externalIntegrations[${key}].enabled.value` as keyof IntegrationFormValues
              }
              render={({ field }) => (
                <AppUI.Toggle
                  onChange={e => {
                    field.onChange(Number(e));
                  }}
                  checked={field.value?.toString() === '1'}
                />
              )}
            />
          )}
        >
          <>
            <div className="flex-1">
              <AppUI.Label title={t('integrations.tab-name')}>
                <AppUI.Input
                  hasErrors={!!errors?.[key]?.['tab_name']}
                  {...register(
                    `externalIntegrations[${key}].tab_name.value` as keyof IntegrationFormValues,
                  )}
                />

                {!!errors?.[key]?.['tab_name'] && (
                  <AppUI.ErrorMessage
                    message={t(errors?.[key]?.['tab_name']?.message || '')}
                  />
                )}
              </AppUI.Label>
            </div>

            {Object.entries(config).map(([configKey, configField]) => {
              if (
                configKey === 'tab_name' ||
                configKey === 'enabled' ||
                configKey === 'field' ||
                configField.type === 'boolean'
              )
                return;

              const fieldName = `externalIntegrations[${key}].${configKey}.value`;

              return (
                <div key={configKey} className="flex-1">
                  <AppUI.Label title={configField.label}>
                    <>
                      <AppUI.Input
                        hasErrors={!!errors?.[key]?.[configKey]}
                        {...register(fieldName as keyof IntegrationFormValues)}
                      />

                      {!!errors?.[key]?.[configKey] && (
                        <AppUI.ErrorMessage
                          message={t(errors?.[key]?.[configKey]?.message || '')}
                        />
                      )}
                    </>
                  </AppUI.Label>
                </div>
              );
            })}

            {Object.entries(config).map(([configKey, configField]) => {
              if (
                configKey === 'tab_name' ||
                configKey === 'enabled' ||
                configKey === 'field' ||
                configField.type === 'string'
              )
                return;

              const fieldName = `externalIntegrations[${key}].${configKey}.value`;

              if (!enableBreakoutApps && configKey === 'enabled_breakout_room')
                return;

              return (
                <div key={configKey} className="h-full">
                  <AppUI.Label title={configField.label}>
                    <Controller
                      control={control}
                      name={fieldName as keyof IntegrationFormValues}
                      render={({ field }) => (
                        <AppUI.Toggle
                          onChange={e => {
                            field.onChange(Number(e));
                          }}
                          checked={field.value?.toString() === '1'}
                        />
                      )}
                    />
                  </AppUI.Label>
                </div>
              );
            })}
          </>
        </AppUI.Card>
      ))}
    </>
  );
};
