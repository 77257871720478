import { useTranslation } from '@hopin-team/provider-translation';
import cx from 'classnames';
import { isBefore } from 'date-fns';

import { Button } from '@/components/button/button';
import { Publish, Undo } from '@/components/icons';
import { Tooltip } from '@/components/tooltip/tooltip';
import { useIsFreePlan } from '@/hooks/useIsFreePlan';
import { useRootDispatch, useRootSelector } from '@/store';
import { getEvent, getFlags } from '@/store/selectors';
import { FLAG_EVENTS_PRO_PLAN } from '@/utils/flags';
import { getBillingDashboardURL } from '@/utils/url-helper';

export const PublishButton = ({ showIcon = true, classNames = '' }) => {
  const { t } = useTranslation();
  const event = useRootSelector(getEvent);
  const dispatch = useRootDispatch();
  const { isFreePlan, isDraft } = useIsFreePlan();

  const flags = useRootSelector(getFlags);
  const isEventsProPlansFlagEnabled = Boolean(flags?.[FLAG_EVENTS_PRO_PLAN]);

  if (!event || (!event.draft && !event.validForDeletion)) {
    return null;
  }

  const { draft, id, maxPrice, organization } = event;
  const { merchantId } = organization;
  const billingDashboardURL = getBillingDashboardURL(organization);

  const needsTicketsSetup =
    !event.hasTickets || (maxPrice !== 0 && !merchantId);
  const invalidStartDate = isBefore(new Date(event.timeStartLocal), new Date());
  const tipText = needsTicketsSetup
    ? t('nav.publish.tickets-tip-text')
    : invalidStartDate
    ? t('nav.publish.invalid-start-tip-text')
    : '';
  const isDisabled = Boolean(draft && tipText);
  const showUpgradeButton = isFreePlan && isDraft;

  const handleButtonClick = () => {
    if (showUpgradeButton) {
      if (isEventsProPlansFlagEnabled) {
        window.location.assign(billingDashboardURL);
      } else {
        dispatch({
          type: 'upgrade dialog opened',
        });
        return;
      }
    }

    const confirm = window.confirm(
      t(
        draft
          ? 'nav.publish.publish-are-you-sure'
          : 'nav.publish.unpublish-are-you-sure',
      ),
    );

    if (confirm) {
      dispatch({
        type: 'header publish button clicked',
        payload: {
          draft,
          eventId: id,
        },
      });
    }
  };

  return (
    <>
      <Tooltip
        tooltip={tipText}
        shouldShow={tipText.length > 0}
        placement="top-center"
      >
        <Button
          onClick={handleButtonClick}
          disabled={isDisabled}
          kind={showUpgradeButton ? 'tertiary' : 'primary'}
          pattern={isDisabled || !draft ? 'outline' : 'filled'}
          size="small"
          className={cx(
            'flex gap-2 whitespace-nowrap border disabled:pointer-events-none',
            classNames ?? '',
          )}
        >
          {showIcon ? (
            draft ? (
              <Publish className="h-3 w-3" />
            ) : (
              <Undo className="h-3 w-3" />
            )
          ) : (
            ''
          )}
          <span>
            {t(
              showUpgradeButton
                ? 'nav.publish.upgrade-to-publish'
                : draft
                ? 'nav.publish.publish'
                : 'nav.publish.unpublish',
            )}
          </span>
        </Button>
      </Tooltip>
    </>
  );
};
