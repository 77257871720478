import { useTranslation } from '@hopin-team/provider-translation';
import cx from 'classnames';
import { LegacyRef } from 'react';

import { CrownAlt } from '@/components/icons';
import { useIsFreePlan } from '@/hooks/useIsFreePlan';

import { useRootDispatch } from '../../../store';
import { paidPlanFeatures } from '../constants';

type SubNavItemProps = {
  childTitle: string;
  link: string;
  currentActiveItem: { key: string };
  scrollToActiveItem: LegacyRef<HTMLButtonElement>;
  tabIndex?: number;
  gatePaidFeatures?: boolean;
};

export const SubNavItem = ({
  childTitle,
  link,
  currentActiveItem,
  scrollToActiveItem,
  tabIndex = 0,
  gatePaidFeatures,
}: SubNavItemProps) => {
  const { t } = useTranslation();
  const dispatch = useRootDispatch();
  const isActive = childTitle === currentActiveItem.key;
  const { isFreeTrialPlan } = useIsFreePlan();
  const isGatedFeature =
    (isFreeTrialPlan && childTitle === 'emails') ||
    (gatePaidFeatures && paidPlanFeatures.includes(childTitle));

  return (
    <button
      tabIndex={tabIndex}
      className={cx(
        'bg-nav-background hover:bg-nav-hover mt-4 flex w-full items-center rounded-lg py-2 pl-12 pr-6 text-left leading-7 text-gray-800 transition hover:text-gray-900',
        {
          'text-primary bg-nav-hover': isActive,
        },
      )}
      onClick={() =>
        void dispatch({
          type: 'link clicked',
          payload: {
            link,
          },
        })
      }
      ref={isActive ? scrollToActiveItem : null}
      data-menu-action={childTitle}
    >
      <h4>{t(`${'nav-items'}.${childTitle}`)}</h4>
      {isGatedFeature && <CrownAlt className="mx-1.5 h-5 w-5" />}
    </button>
  );
};
