import { useRootSelector } from '@/store';
import { getEvent } from '@/store/selectors';

export const useIsFreePlan = () => {
  const event = useRootSelector(getEvent);

  if (!event) {
    return { isFreePlan: false, isDraft: false, isLive: false };
  }

  const { organization, phase } = event;
  const { currentPaymentPlan } = organization;

  const isFreePlan = currentPaymentPlan.name === 'Free';
  const isFreeTrialPlan = currentPaymentPlan.name === 'Free_trial';
  const isPublishAllowed = currentPaymentPlan.features.publish_event;

  return {
    isFreePlan: Boolean(isFreePlan && !isPublishAllowed),
    isFreeTrialPlan: Boolean(isFreeTrialPlan),
    isDraft: phase === 'Draft',
    isLive: phase === 'Live',
  };
};
